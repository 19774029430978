import React, { useState } from 'react';

import { Document } from 'react-pdf';
import PDFThumbnail from './PDFThumbnail.tsx';

console.log('Hello PDFThumbBar');

interface PDFThumbBarProps {
  file: File | string | null;
  currentPage: number;
  pdf: any; // Access the pdf prop passed from PDFViewer
  numPages: number;
  pageNumber: number;
  showSidebar?: boolean | null;
  setCurrentPage: (pageNumber: number) => void;
  scrollToPage: (pageNumber: number) => void;
}

const PDFThumbBar: React.FC<PDFThumbBarProps> = ({ file, numPages, setCurrentPage, scrollToPage }) => {
  const [thumbnailsRendered, setThumbnailsRendered] = useState(new Array(numPages).fill(false));

  const onThumbnailRender = (pageNumber: number) => {
    setThumbnailsRendered(prevState => {
      const newState = [...prevState];
      newState[pageNumber - 1] = true;
      return newState;
    });
  };

  return (

      <Document file={file}>
        {Array.from(new Array(numPages), (el, index) => (
          <PDFThumbnail key={`thumb_${index + 1}`} pageNumber={index + 1} file={file} currentPage={0} isLoading={false} setCurrentPage={setCurrentPage} scrollToPage={scrollToPage} onThumbnailRender={onThumbnailRender} thumbnailRendered={thumbnailsRendered[index]} />
        ))}
      </Document>

  );
};

export default PDFThumbBar;
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

interface ResponsiveDialogProps {
  dialogueOpen: boolean;
  setDialogueOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResponsiveDialog: React.FC<ResponsiveDialogProps> = ({ dialogueOpen, setDialogueOpen}) => {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


  const handleClose = (e) => {
    if  (e.target.value === 'agree') {
          // Generate uuid
          const uuid = uuidv4();

          // Set key:value '_sk_doc':uuid_value in cookies, with an expiry of 365 days
          Cookies.set('_sk_doc', uuid, {expires: 365});

          setDialogueOpen(false);
        }
      };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={dialogueOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Use Skhema's Document Viewer?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Legal Notice: Your data is yours. Skhema does not store any of your documents. When using 'drag n drop' your document's data doesn't leave the browser. If you agree, click 'Agree' to continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} value={'disagree'} aria-label='disagree'>
            Disagree
          </Button>
          <Button onClick={handleClose} value={'agree'} aria-label='agree' autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default ResponsiveDialog;
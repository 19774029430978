import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PDFViewer from './PDFViewer.tsx';
import Dropzone from './Dropzone.tsx';
import PDFToolbar from './Toolbar.tsx';
import SnackbarAlert from './SnackbarAlert.tsx';
import ResponsiveDialog from './Dialog.tsx';
import DownloadExtensionAlert from './DownloadExtension.tsx';

// Material UI
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

type PDFFile = File | string | null;

interface AppProps {
  // onNewDocumentClick: () => void;
  // toggleExtensionPanel: () => void;
}

const App: React.FC<AppProps> = () => {
  const [file, setFile] = useState<PDFFile>(null);
  const [isDropzoneDisplayed, setIsDropzoneDisplayed] = useState<boolean | null>(true); // Show dropzone by default, hide if file is uploaded
  const [showSidebar, setShowSidebar] = useState<boolean | null>(true); // Show sidebar by default
  const navigate = useNavigate();

  // SnackbarAlert
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState(''); // 'error' | 'info' | 'success' | 'warning';
  const [open, setOpen] = useState(false);

  // DownloadExtensionAlert
  const [openExtensionAlert, setOpenExtensionAlert] = useState(false);
  const [isDocContentScriptReady, setIsDocContentScriptReady] = useState(false); // Define a variable to keep track of whether the doc-content-script is available or not

  // Responsive dialogue
  const [dialogueOpen, setDialogueOpen] = React.useState(false);

  // On id="new-document" Button Click, show dropzone and reset file
  const handleButtonClick = () => {
    if (isDropzoneDisplayed === false) { // If dropzone is hidden, show it
      setIsDropzoneDisplayed(!isDropzoneDisplayed);
      setFile(null);
    }
    // Clear the query parameters from the URL
    navigate(window.location.pathname, { replace: true });
  };

  // On id="toggle-sidebar" Button Click, toggle sidebar
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
    // setShowSidebar((prevState) => !prevState);
    console.log('Toggle Sidebar button clicked', 'Here is the state of showSidebar:', showSidebar);
  }


// Handle the event to chenck if doc-content-script is available and has sent a message to the window
useEffect(() => {
  const handleExtensionMessage = (event: MessageEvent ) => {
    if (event.data.type === 'DOC_CONTENT_SCRIPT_READY') {
      console.log('skhema-document-viewer received message DOC_CONTENT_SCRIPT_READY');
      setIsDocContentScriptReady(true);
    }
  };
  window.addEventListener('message', handleExtensionMessage);
  // Clean up the event listener when the component is unmounted
  return () => {
    window.removeEventListener('message', handleExtensionMessage);
  };
}, []); // End of useEffect

// The handleExtension function sends an alert if  doc-content-script is not available
const handleExtension = () => {
  console.log('Toggle Extension Panel button clicked');
  if (isDocContentScriptReady) {
    console.log('DOC_CONTENT_SCRIPT_READY');
  } else {
    setOpenExtensionAlert(true); // Open the alert in the UI 'Download the Skhema Extension'
  }
};


// Check if the user has already seen the Legal Notice dialog, by checking if the cookie '_sk_doc' exists
    useEffect(() => {
      const hasStoredCookie = document.cookie.includes('_sk_doc');
      if (!hasStoredCookie) {
        setDialogueOpen(true);
      }
    }, []);


  function CopyrightAndLegal() {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://skhema.com/" underline='hover' target="_blank">
          Skhema Pty Ltd
        </Link>{' '}
        {new Date().getFullYear()}.{' | '}
        <Link color="inherit" href="https://skhema.com/legal" underline='hover' target="_blank">
          Legal
        </Link>{' | '}
        <Link color="inherit" href="https://skhema.com/legal/privacy-policy" underline='hover' target="_blank">
          Privacy
        </Link>{' | '}
        <Link color="inherit" href="https://skhema.com/legal/terms-of-service" underline='hover' target="_blank">
          Terms of Service
        </Link>
      </Typography>
    );
  }

  return (
    <>
      {/* Toolbar */}
      <PDFToolbar onNewDocumentClick={handleButtonClick} toggleSidebar={toggleSidebar} toggleExtensionPanel={handleExtension} />

      {/* File Input Container: accepts drag & drop and file url input */}
      {isDropzoneDisplayed && <Dropzone setFile={setFile} isDropzoneDisplayed={isDropzoneDisplayed} setIsDropzoneDisplayed={setIsDropzoneDisplayed} setOpen={setOpen} setSeverity={setSeverity} setMessage={setMessage}/>}

      {/* PDFViewer */}
      {file && <PDFViewer file={file} showSidebar={showSidebar} setOpen={setOpen} setSeverity={setSeverity} setMessage={setMessage}/>}


      {/* CopyrightAndLegal footer: show only on home page */}
      {isDropzoneDisplayed && <Container sx={{ minWidth: '100%', height: '40px', bgcolor: '#F9F9FA' }}>
        <Box sx={{ padding: '8px 0px' }}>
          <CopyrightAndLegal />
        </Box>
      </Container>}

      {/* Fab: New Document Button */}
      <Fab id="new-document" size="large" color="secondary" className="fab" onClick={handleButtonClick} title="New Document" aria-label="new-document"
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(4),
          right: (theme) => theme.spacing(5)
        }}>
        <AddIcon />
      </Fab>

      {/* Dialog: Legal Notice dialog */}
      {dialogueOpen && <ResponsiveDialog dialogueOpen={dialogueOpen} setDialogueOpen={setDialogueOpen} />}

      {/* SnackbarAlert */}
    {severity !== '' && <SnackbarAlert open={open} message={message} severity={severity} />}

    {/* DownloadExentionAlert */}
    {openExtensionAlert && <DownloadExtensionAlert openExtensionAlert={openExtensionAlert} setOpenExtensionAlert={setOpenExtensionAlert} />}
    </>
  );
}

export default App;

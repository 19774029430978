import React, { useEffect } from 'react';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

import './App.css';

console.log('Hello SnackbarAlert');

interface State extends SnackbarOrigin {
    open: boolean;
    severity: string; // 'error' | 'info' | 'success' | 'warning';
  }
  
  interface SnackbarAlertProps {
    open: boolean;
    message: string;
    severity: string; // 'error' | 'info' | 'success' | 'warning';
  }

  const SnackbarAlert: React.FC<SnackbarAlertProps> = ({ message, open: openProp, severity: severityProp }) => {
    console.log('SnackbarAlert message:', message, 'openPropValue:', openProp);


  const [state, setState] = React.useState<State>({
    open: openProp,
    vertical: 'bottom',
    horizontal: 'center',
    severity: severityProp,
      });

  useEffect(() => {
    setState(prevState => ({ ...prevState, open: openProp, severity: severityProp}));
  }, [openProp, severityProp]);

  const { vertical, horizontal, open, severity } = state;

console.log('SnackbarAlert state:', state);

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  return (
    <div>
      <ClickAwayListener onClickAway={() => {}}>
        <Snackbar
          message={message}
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
          ClickAwayListenerProps={{ onClickAway: () => null }}
        >
          <Alert 
            onClose={handleClose} 
            severity={severity as 'error' | 'info' | 'success' | 'warning'}
            variant='filled'
          >
            {message} 
          </Alert>
        </Snackbar>
      </ClickAwayListener>
    </div>
  );
}
export default SnackbarAlert;

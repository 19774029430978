import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './App.css';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import PDFThumbBar from './PDFThumbBar.tsx';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

// @TODO: Find way to access load steam reader PDFWorkerStreamReader;

const resizeObserverOptions = {};
const maxWidth = 800;

interface PDFViewerProps {
    file?: File | string | null;
    showSidebar?: boolean | null;
    searchText?: string;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setSeverity: React.Dispatch<React.SetStateAction<string>>;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
}

console.log('Hello PDFViewer');

// Leave options outside of react component, due to rendering issues.
const options = {
    cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
    cMapPacked: true,
};

// Search function, highlight matching text
function highlightPattern(text, pattern) {
    return text.replace(pattern, (value) => `<mark>${value}</mark>`);
}

const PDFViewer: React.FC<PDFViewerProps> = ({ file, showSidebar, searchText, setOpen, setSeverity, setMessage }) => {
    const [numPages, setNumPages] = useState<number>(0);
    const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
    const [containerWidth, setContainerWidth] = useState<number>();

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pdf, setPdf] = useState<PDFDocumentProxy | null>(null);

    // Resize observer to track container width
    const onResize = useCallback<ResizeObserverCallback>((entries) => {
        const [entry] = entries;

        if (entry) {
            setContainerWidth(entry.contentRect.width);
        }
    }, []);
    useResizeObserver(containerRef, resizeObserverOptions, onResize);


    function onDocumentLoadSuccess(param: any): void {
        setNumPages(param.numPages);
        setPdf(param.pdf);
        console.log('Function onDocumentLoadSuccess: ', 'Param:', param, 'datetime', new Date().toLocaleTimeString());

        // Send a dispatch event to the Skhema Chrome Extension
        dispatchEvent(new CustomEvent('UPDATE_DOCUMENT_DETAILS', { detail: { numPages: param.numPages } }));
    }

    function onDocumentLoadProgress(param: any): void {
        setOpen(true); // Open the SnackbarAlert
        setMessage(`${pagesRendered} out of ${numPages} pages rendered`);
        console.log('Function onDocumentLoadProgress:', 'Param:', param, 'datetime', new Date().toLocaleTimeString());
    }

    // Search text in document and highlight matches
    const textRenderer = useCallback(
        (textItem) => highlightPattern(textItem.str, searchText),
        [searchText]
    );

    // Return the number of pages rendered, and discontinue the function when reached.
    const [pagesRendered, setPagesRendered] = useState(0);
    const setPagesRenderedRef = useRef(setPagesRendered);


    useEffect(() => {
        setPagesRenderedRef.current = setPagesRendered;
    }, [setPagesRendered]);

    useEffect(() => {
        setPagesRendered(0); // Reset pagesRendered when numPages changes
    }, [numPages]);

    const onRenderTextLayerSuccess = () => {
        if (pagesRendered < (numPages ?? 0)) {
            setPagesRenderedRef.current(prevPagesRendered => prevPagesRendered + 1);
            setOpen(true);
            setSeverity('success');
            setMessage(`${pagesRendered} out of ${numPages} pages rendered`);
        } else {
            setOpen(false);
            // console.log('All pages rendered, changing setOpen to false', open);
        }
        // console.log(`${pagesRendered} out of ${numPages} pages rendered`);
    };


    // Scroll to page when thumbnail is clicked
    const viewerRef = useRef<HTMLElement | null>(null);

    const scrollToPage = useCallback((pageNumber) => {
        const pageElement = document.querySelector(`.pdf-page${pageNumber}`);
        pageElement?.scrollIntoView({ behavior: 'smooth' });

        console.log("pageElement", pageElement)
        console.log('Set page target className:', `.pdf-page${pageNumber}`)
    }, []);

    return (
        <div className="viewer-container" >

            <div className={`pdf-viewer-sidebar ${showSidebar ? 'block' : 'hidden'}`}>
                {/* PDFThumbBar */}
                {<PDFThumbBar numPages={numPages ?? 0} pdf={pdf} currentPage={currentPage} setCurrentPage={setCurrentPage} file={file || null} scrollToPage={scrollToPage} pageNumber={0} />}
            </div>

            {/* Document */}
            <div className="document-container" ref={setContainerRef}>
                <Document
                    file={file}
                    options={options}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadProgress={onDocumentLoadProgress}
                    ref={viewerRef}
                    onItemClick={scrollToPage}
                >
                    {/* Pages */}
                    <div className="react-pdf__Page"></div>
                    {Array.from(new Array(numPages), (_, index) => (
                        <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                            customTextRenderer={textRenderer} // Highlight search text
                            onLoadSuccess={() => console.log('Page loaded')}

                            onRenderTextLayerSuccess={onRenderTextLayerSuccess} // Increment pagesRendered, and show Snackbar
                            className={`pdf-page${index + 1}`}
                        />
                    ))}
                </Document>
            </div>
        </div>
    );
};

export default PDFViewer;

import React from 'react';
import { Thumbnail } from 'react-pdf';

import Skeleton from '@mui/material/Skeleton';

console.log('Hello PDFThumbnail');

interface PDFThumbnailProps {
  file: File | string | null;
  pageNumber: number;
  currentPage: number;
  isLoading: boolean;
  thumbnailRendered: boolean;
  setCurrentPage: (pageNumber: number) => void;
  scrollToPage: (pageNumber: number) => void;
  onThumbnailRender: (pageNumber: number) => void;
}

const PDFThumbnail: React.FC<PDFThumbnailProps> = ({ pageNumber, currentPage, scrollToPage, onThumbnailRender, thumbnailRendered }) => {

  const onClickThumbnail = () => {
    // setCurrentPage(pageNumber);
    scrollToPage(pageNumber);
    console.log('Thumbnail clicked, pageNumber:', pageNumber);
  };

  const handleRenderSuccess = () => {
    onThumbnailRender(pageNumber);
  };


  return (
    <div className={`thumbnail ${currentPage === pageNumber ? 'focused' : ''}`}  >

      <Thumbnail
        width={140}
        onItemClick={() => onClickThumbnail()}
        pageNumber={pageNumber}
        onRenderSuccess={handleRenderSuccess}
      />

      {!thumbnailRendered && <Skeleton className="thumbnail skeleton" variant="rectangular" width={140} height={198} />}

    </div>
  );
};

export default PDFThumbnail;


import React, { useEffect } from 'react';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';

import Alert from '@mui/material/Alert';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import './App.css';

console.log('Hello DownloadExtension.tsx');

interface State extends SnackbarOrigin {
    open: boolean;
    severity: string; // 'error' | 'info' | 'success' | 'warning';
}

interface DownloadExtensionAlertProps {
    openExtensionAlert: boolean;
    setOpenExtensionAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

const DownloadExtensionAlert: React.FC<DownloadExtensionAlertProps> = ({ openExtensionAlert: openProp, setOpenExtensionAlert }) => {
    console.log('DownloadExtensionAlert ', 'openPropValue:', openProp);


    const [state, setState] = React.useState<State>({
        open: openProp,
        vertical: 'top',
        horizontal: 'right',
        severity: 'info'
    });

    useEffect(() => {
        setState(prevState => ({ ...prevState, open: openProp, severity: 'info' }));
    }, [openProp]);

    const { vertical, horizontal, open, severity } = state;

    console.log('DownloadExtensionAlert state:', state);

    const handleClose = () => {
        setOpenExtensionAlert(false);
    };


    const action = (
        <React.Fragment>
            <Button color="inherit" size="small" onClick={handleClose} href="https://chromewebstore.google.com/detail/gajeelhcgpghmkghanelgjjbohbgdamd" target="_blank" rel="noopener noreferrer">
                INSTALL NOW
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        // Box used to position the alert at the top right corner of the screen
        <Box id="download-extension-alert"
            sx={{
                top: "24px",
                right: "0px",
                width: "100%",
                height: "100px",
                position: "fixed",
            }}>
            <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}
                sx={{ position: "absolute" }}
            >
                <Alert
                    onClose={handleClose}
                    severity='info'
                    variant='filled'
                    action={action}
                >
                    Install the Skhema Chrome Extension.
                </Alert>
            </Snackbar>

        </Box>
    );
}

export default DownloadExtensionAlert;

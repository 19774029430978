import React from 'react';
import './App.css';

import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';

import IconButton from '@mui/material/IconButton';

import Typography from '@mui/material/Typography';


interface ToolbarProps {
  onNewDocumentClick: () => void;
  toggleSidebar: () => void;
  toggleExtensionPanel: () => void;
}

function ToggleSidebar() {
  return (
    <SvgIcon >
      <svg id="dock_to_right" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M224.62-160q-26.85 0-45.74-18.88Q160-197.77 160-224.62v-510.76q0-26.85 18.88-45.74Q197.77-800 224.62-800h510.76q26.85 0 45.74 18.88Q800-762.23 800-735.38v510.76q0 26.85-18.88 45.74Q762.23-160 735.38-160H224.62ZM320-200v-560h-95.38q-9.24 0-16.93 7.69-7.69 7.69-7.69 16.93v510.76q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69H320Zm40 0h375.38q9.24 0 16.93-7.69 7.69-7.69 7.69-16.93v-510.76q0-9.24-7.69-16.93-7.69-7.69-16.93-7.69H360v560Zm-40 0H200h120Z" /></svg>
    </SvgIcon>
  );
}

function NewDocument() {
  return (
    <SvgIcon >
      <svg id="note_add" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M460-460H240v-40h220v-220h40v220h220v40H500v220h-40v-220Z" /></svg>
    </SvgIcon>
  );
}

function ToggleExtentionPanel() {
  return (
    <SvgIcon >
      <svg id="dock_to_left" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M224.62-160q-26.85 0-45.74-18.88Q160-197.77 160-224.62v-510.76q0-26.85 18.88-45.74Q197.77-800 224.62-800h510.76q26.85 0 45.74 18.88Q800-762.23 800-735.38v510.76q0 26.85-18.88 45.74Q762.23-160 735.38-160H224.62ZM640-200h120v-535.38q0-9.24-7.69-16.93-7.69-7.69-16.93-7.69H640v560Zm-40 0v-560H224.62q-9.24 0-16.93 7.69-7.69 7.69-7.69 16.93v510.76q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69H600Zm40 0h120-120Z" /></svg>
    </SvgIcon>
  );
}

const PDFToolbar: React.FC<ToolbarProps> = ({ onNewDocumentClick, toggleSidebar, toggleExtensionPanel }) => {
 
  return (
    <div className="toolbar">
      <div className="toolbar-container">
        <div className="toolbar-viewer">
          <div className="toolbar-viewer-left">
            <Stack direction="row" spacing={3} alignItems="flex-end">
              <IconButton id="toggle-sidebar" onClick={toggleSidebar} color="primary" title="Toggle Sidebar" aria-label="toggle-sidebar">
                <ToggleSidebar />
              </IconButton>
            </Stack>
          </div>
          <div className="toolbar-viewer-right">
            <Stack direction="row" spacing={0} alignItems="flex-end">
              <IconButton id="new-document" onClick={onNewDocumentClick} color="primary" title="New Document" aria-label="new-document">
                <NewDocument />
              </IconButton>
              <IconButton id="toggle-extension-panel" onClick={toggleExtensionPanel} color="primary" title="Toggle Extension Panel" aria-label="toggle-extension-panel">
                <ToggleExtentionPanel />
              </IconButton>
            </Stack>
          </div>
          <div className="toolbar-viewer-middle">
            <Stack direction="row" spacing={0} alignItems="baseline" >
              <img src="https://skhema-public-assets.syd1.digitaloceanspaces.com/brand/skhema_wordmark_black.png" alt="logo" style={{ height: '20px', maxHeight: '20px' }} />
              <Typography variant='h6' style={{ color: '#5f6368', fontSize: '28px', fontWeight: 400, marginLeft: '8px' }}>|</Typography>
              <Typography variant='h6' style={{ color: '#5f6368', fontSize: '16px', fontWeight: 400, marginLeft: '8px', alignSelf: "center" }}>Document Viewer</Typography>
              {/* <span style={{ color: '#5f6368', fontSize: '12px', fontWeight: 500, marginLeft: '8px' }}> | Document Viewer</span> */}
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PDFToolbar;
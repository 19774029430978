import React, { useState, useMemo, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import './App.css';

// Material UI
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface DropzoneProps {
  setFile: React.Dispatch<React.SetStateAction<File | string | any | null>>;
  isDropzoneDisplayed: boolean;
  setIsDropzoneDisplayed: React.Dispatch<React.SetStateAction<boolean | null>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSeverity: React.Dispatch<React.SetStateAction<string>>;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
}

// Styles for react-dropzone
const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 1,
  borderRadius: '4px',
  borderColor: '#000000de',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};


const Dropzone: React.FC<DropzoneProps> = ({ setFile, setIsDropzoneDisplayed, setOpen, setSeverity, setMessage }) => {
  const [documentURL, setDocumentURL] = useState<string | null>(null);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: { 'application/pdf': [".pdf"] },
    maxFiles: 1,
    multiple: false,
    noClick: false,
    noKeyboard: true,
    minSize: 0,
    maxSize: 50 * 1024 * 1024,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length === 0) {
        setSeverity('error');
        setMessage('Invalid file format. Only PDF files are accepted.');
        setOpen(true);
        setIsDropzoneDisplayed(true);
      } else {
        setFile(acceptedFiles[0]);
        console.log(acceptedFiles[0].name); // Access the name of the document
        // Send a dispatch event to the Skhema Chrome Extension
        dispatchEvent(new CustomEvent('UPDATE_DOCUMENT_DETAILS', { detail: { file_name: acceptedFiles[0].name } }));

        setIsDropzoneDisplayed(false);
      }
    }
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  // @NOTE: Endpoint used as proxy to get base64 encoded PDF content
  // eslint-disable-next-line no-console
  const uploadPDF = async (fileURL: string) => {
    try {
      const response = await fetch('https://api.skhema.com/api:PTyRxFqE/pdf-content', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ document_url: fileURL })
      });
      const responseData = await response.json();
      console.log('API Response:', response, 'status_code', response.status, 'status_text:', response.statusText);
      console.log('Response data:', responseData);
      if (response.status === 200) {
        return responseData;
      } else {
        setSeverity('error');
        setMessage('Error loading PDF file');
        setOpen(true);
        setIsDropzoneDisplayed(true); // Keep the dropzone displayed
      }
    } catch (error) {
      setSeverity('error');
      setMessage('Error loading PDF file');
      setOpen(true);
      setIsDropzoneDisplayed(true); // Keep the dropzone displayed
    }
  };

  // eslint-disable-next-line no-console
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('file')) {
      const fileURL = urlParams.get('file');
      if (fileURL) { // Add null check
        uploadPDF(fileURL).then(responseData => {
          console.log('File object:', 'file_name:', responseData.file_name, 'file_size:', responseData.file_size, 'file_type:', responseData.file_type, 'file_url:', responseData.file_url);

          // Send a dispatch event to the Skhema Chrome Extension
          dispatchEvent(new CustomEvent('UPDATE_DOCUMENT_DETAILS', { detail: { file_name: responseData.file_name, file_url: responseData.file_url } }));

          setFile(responseData.file_base64); // base64 encoded PDF content
          setIsDropzoneDisplayed(false);
        });
      } else {
        setSeverity('error');
        setMessage('No file URL found in query parameters');
        setOpen(true);
        setIsDropzoneDisplayed(true); // Keep the dropzone displayed
        console.error('No file URL found in query parameters');
      }
    }
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const inputElement = event.currentTarget.elements.namedItem('document-url') as HTMLInputElement;
    const fileURL = new URL(inputElement.value, import.meta.url).toString();
    uploadPDF(fileURL).then(responseData => {
      if (responseData) {
        console.log('File object:', 'file_name:', responseData.file_name, 'file_size:', responseData.file_size, 'file_type:', responseData.file_type, 'file_url:', responseData.file_url);

        // Send a dispatch event to the Skhema Chrome Extension
        dispatchEvent(new CustomEvent('UPDATE_DOCUMENT_DETAILS', { detail: { file_name: responseData.file_name, file_url: responseData.file_url } }));

        setFile(responseData.file_base64); // base64 encoded PDF content
        setIsDropzoneDisplayed(false);
      } else {
        setSeverity('error');
        setMessage('Error loading PDF file');
        setOpen(true);
        setIsDropzoneDisplayed(true); // Keep the dropzone displayed
      }

    });
  };

  return (
    <div className="file-input-container" >
      <Typography variant="h3" gutterBottom>
        Open a PDF document
      </Typography>

      <form onSubmit={handleSubmit} className="document-url">
        <TextField
          // error
          id="document-url"
          label="Open from URL"
          fullWidth
          value={documentURL}
          onInput={e => setDocumentURL((e.target as HTMLInputElement).value)}
          helperText="Example: https://example.com/document.pdf"
        />
      </form>
      <Box sx={{ marginBottom: '18px', display: 'flex', width: '55%', alignItems: 'center', gap: 2 }}>
        <Box sx={{ flex: 1, height: '1px', bgcolor: '#CCCCCC' }} />
        <Typography variant="h6" gutterBottom>
          OR
        </Typography>
        <Box sx={{ flex: 1, height: '1px', bgcolor: '#CCCCCC' }} />
      </Box>

      <div className="dropzone-container">
        <div {...getRootProps({ style: style as React.CSSProperties })}>
          <input {...getInputProps()} />
          <Typography variant="body1" display="block" gutterBottom>
            Drag 'n' drop a file here, or click to select file
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            (Only *.pdf documents are accepted)
          </Typography>
        </div>
      </div>

    </div>
  );

}


export default Dropzone;

